import './form'
import './popup'
import './animation'
import './ui-elements'


$('.js-load-more').click(function (e) {
	e.preventDefault();
	let button = $(this);
	let paged = button.attr("data-paged");
	let max  = button.attr("data-max");	
	let posttype  = button.attr("data-posttype");
	let resultdiv  = button.attr("data-resultdiv");
	let postcount  = button.attr("data-postcount");
	let data={
		'max': max,
		'paged': paged,		
		'posttype': posttype,	
		'postcount': postcount,		
		'action': 'load_more_product'
	};
	$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		data: data,
		error: function (jqXHR, exception) {
		},
		beforeSend : function ( xhr ) {		
			button.text('Загружается...'); 
		},
		success : function( data ){
			button.html('<span> Загрузить еще </span>');
			console.log(data);
			paged++;

			if (paged == max) {
				button.hide();
			}
			if (data) {
				jQuery('.'+resultdiv).append(data);
				button.html('<span> Загрузить еще </span>');
				button.attr('data-paged', paged);
				console.log(data);
	
				
			} else {
				button.hide();
			}
		}
	});
});

$('.js-nav-btn').on('click',function(){
	var $this = $(this);
	var navBlock = $('.js-nav-block');
	$this.toggleClass('burger_active');
	navBlock.slideToggle();
	$('.header-col--nav').toggleClass('active'); 
});

$('.scrollbar-inner').scrollbar();


// Если на главной то скролим
$(".main-menu-top .anchor a, .footer-menu .anchor a").click(function(e){
  e.preventDefault(); 
  var anchor = $(this).attr("href").substring($(this).attr("href").lastIndexOf('/') + 1);
  console.log(anchor);
  $("html, body").animate({
    scrollTop: ($(anchor).offset().top-100) + "px"
  });
	if($(window).width()<992 ){
		$('.js-nav-btn').removeClass('burger_active');
		$('.header-col--nav').removeClass('active'); 

	}	
});

$(".go-top").click(function(e){
  e.preventDefault(); 
  var anchor = $(this).attr("href").substring($(this).attr("href").lastIndexOf('/') + 1);
  console.log(anchor);
  $("html, body").animate({
    scrollTop: ($(anchor).offset().top-100) + "px"
  });

});

$('.js-no-site').on('change',function(e){
	 e.preventDefault();
	if ($(this).is(':checked')){
		$('.js-website').prop('disabled', true);
	} else {
		$('.js-website').prop('disabled', false);
	}
});



var myHash = location.hash; //получаем значение хеша
location.hash = ''; //очищаем хеш
if(myHash[1] != undefined){ //проверяем, есть ли в хеше какое-то значение
	$('html, body').animate({scrollTop: $(myHash).offset().top-100}, 500); //скроллим за полсекунды
};


$('.about-item').on("click",function(e){
	e.preventDefault();
	$('.about-item').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info').removeClass('active');
	$('.about-item__info[data-tab='+tab+']').addClass('active');
});

$(document).on("click",".about-item__info.active .about-item__btn",function(e) {
	e.preventDefault();
	$('.about-item__info.active .about-item__btn').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info.active .about-item__tab-content').removeClass('active');
	$('.about-item__info.active .about-item__tab-content[data-tab='+tab+']').addClass('active');
});

$('.about-item').on("click",function(e){
	e.preventDefault();
	$('.about-item').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info').removeClass('active');
	$('.about-item__info[data-tab='+tab+']').addClass('active');
});


// function filter(){
			
// 	productcat=get_idarray($('.filter-section_cat input[type="checkbox"]:checked'));		
// 	technic_ids=get_idarray($('.filter-section_tehnic input[type="checkbox"]:checked'));
// 	size_ids=get_idarray($('.filter-section_productsize input[type="checkbox"]:checked'));
// 	years_ids=get_idarray($('.filter-section_productyear input[type="checkbox"]:checked'));
// 	theme_ids=$('.filter-section_productheme input[type="checkbox"]:checked').val();

// 	order_by=$('.filter-section_order input[type="radio"]:checked').val();

	
// 	price_min=$('.filter-section_price input[type="radio"]:checked').data('price_min');
// 	price_max=$('.filter-section_price input[type="radio"]:checked').data('price_max');

// 	if($('input[name=price_min]').length) {
// 		if($('input[name=price_min]').val().length>0) { price_min=$('input[name=price_min]').val(); console.log($('input[name=price_min]').val()); }
// 		if($('input[name=price_max]').val().length>0) { price_max=$('input[name=price_max]').val(); console.log($('input[name=price_max]').val()); }
// 	}
// 	filter_type=$('.filer-data').data('filter_type'); 

// 	data = {
// 		action: 'postfilter',
// 		tags_ids: tags_ids,

// 	} 	

// 	console.log(data);

// 	$.ajax({
// 		url: '/wp-admin/admin-ajax.php',
// 		data: data,
// 		type: 'POST',
// 		beforeSend: function(){				
// 		},  
// 		success: function(data){  
// 			console.log(data);
// 			$('.js-products-results').html(data);					
// 			$('.pagination').addClass('d-none');
// 		}
// 	})


// }

$(window).scroll(function(){
  var aTop = 0;
  var navblock= $('header'); 
  if($(this).scrollTop()>aTop){
    navblock.addClass('fixed');
  } else{
    navblock.removeClass('fixed');
  }
});
// .js-set-law
$('.js-set-law').on("click",function(e){
	e.preventDefault();
	let formtitle = $(this).data("formtitle");
	console.log(formtitle);
	$('.js-form input[name=title]').val(formtitle);
}); 
Fancybox.bind("[data-fancybox]", {
	// Your options go here
});
console.log(Fancybox);
// ;()js-set-law

if($("div").is(".js-results-swiper")){ 
  var reviewsslider = new Swiper('.js-results-swiper', {
    spaceBetween: 0,		
    slidesPerView: 1,
    breakpoints: {
      // 992: {
      // 	slidesPerView: 2
      // },
			// 768: {
			// 	slidesPerView: 2
			// },
			// 580: {
			// 	slidesPerView: 1
			// }
    },
    navigation: {
      nextEl: '.js-results-slider__next',
      prevEl: '.js-results-slider__prev',
    },
  });
}

if($("div").is(".js-calc-swiper")){ 
  var calc = new Swiper('.js-calc-swiper', {
    spaceBetween: 0,		
    slidesPerView: 1,
		allowTouchMove: false,
		autoHeight: true, 
		pagination: {
			el: '.calc-pagination',
			clickable: true,
		},
    breakpoints: {
      // 992: {
      // 	slidesPerView: 2
      // },
			// 768: {
			// 	slidesPerView: 2
			// },
			// 580: {
			// 	slidesPerView: 1
			// }
    },
    // navigation: {
    //   nextEl: '.js-results-slider__next',
    //   prevEl: '.js-results-slider__prev',
    // },
		on: {
			reachEnd: function () {
				$('.js-step-next').hide();
			},
		}
  });

	// calc.disableTouchControl();
	$('.js-step-next').click(function(e){
		e.preventDefault();
		calc.slideNext();
	});
}




if($("div").is(".js-team-swiper")){ 
  var reviewsslider = new Swiper('.js-team-swiper', {
    spaceBetween: 20,		
    slidesPerView: 5,
    breakpoints: {
      992: {
      	slidesPerView: 2
      },
			768: {
				slidesPerView: 2
			},
			580: {
				slidesPerView: 1
			}
    },
    navigation: {
      nextEl: '.js-team__next',
      prevEl: '.js-team__prev',
    },
  });
}


function YouTubeGetID(url) {
	var ID = '';
	url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
	if (url[2] !== undefined) {
			ID = url[2].split(/[^0-9a-z_\-]/i);
			ID = ID[0];
	} else {
			ID = url;
	}
	return ID;
}

function setPosterUrl() {

	let videoID, posterUrl;

	var images = [
			// 'maxresdefault.jpg',
			// 'sddefault.jpg',
			// 'hqdefault.jpg',
			'mqdefault.jpg'
			// 'default.jpg'
	];  


	let video_items=document.querySelectorAll('.video-item');

	video_items.forEach(function(item, index){

			let url=item.dataset.vid;

			console.log(item);
			console.log(url);

			videoID = YouTubeGetID(url);

			posterUrl = 'https://i.ytimg.com/vi/'+ videoID +'/'+ images[0];
		 
			item.style.backgroundImage ='url('+posterUrl+')';
	});
	

}
setPosterUrl();


$('.accordion-items .accordion-item__header').on("click",function(e){
	e.preventDefault();
	// $('.accordion-items .accordion-item__header').removeClass('active');
	$(this).toggleClass("active");
	
	$('.accordion-items .accordion-item__header').not(this).parent().find('.accordion-item__body').slideUp();

	$(this).parent().find('.accordion-item__body').slideToggle();
});

$('.servicelist-item__header').on("click",function(e){
	e.preventDefault();
	$(this).toggleClass("active");	
	$('.servicelist-item__header').not(this).parent().find('.servicelist-item__body').slideUp();
	$(this).parent().find('.servicelist-item__body').slideToggle();
});


if (($(".js-case-slider").length > 0 )) {

		$('.js-case-slider').each(function(){
				var swiperTrusted = new Swiper(this, {
					loop: false,
					noSwiping: false,
					spaceBetween: 0,
					watchSlidesVisibility: true,
					slidesPerView: 1,
					pagination: {
						el: '.case-pagination',
						clickable: true,
					}, 
					// breakpoints: {
					// 	992: {
					// 		slidesPerView: 3,
					// 		noSwiping: false,
					// 		noSwiping: true,
					// 	},
					// 	768: {
					// 		slidesPerView: 2,
					// 		noSwiping: false,
					// 		noSwiping: true,
					// 	},
					// },
					// navigation: {
					// 	nextEl: this.querySelector('.js-s-next'),
					// 	prevEl: this.querySelector('.js-s-prev'),
					//  },
				}); 
		});



}

if (!sessionStorage.alreadyClicked) {

	setTimeout(function() {


		let $popup = $('.js-popup');
		const $elem = $('.js-popup[data-block="commercia-offer"]');	
		$popup.removeClass('is-show');
		$elem.addClass('is-show');

}, 10000); // milliseconds



	sessionStorage.alreadyClicked = "true";
}


